.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.container {
    background-color: #f3f6f7 !important;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    justify-content : center;
    flex-direction : column;
    text-align : center;
    max-width: 500px;
    margin: auto;
}
.select_color{
    display: flex;
    justify-content: center;
    flex-direction : column;
    align-items: center;
    background-color: #e8e8e8;
    width: 58px;
    height:58px;
    border-radius: 7px;
    cursor: pointer;
    margin: 5px;
    border: #e8e8e8 2px solid;
    transition-duration: 300ms;
}
.selected_color{
    border: #5856d6 2px solid;
    transition-duration: 300ms;
}
.select_color .color{
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
body{
    background-color: #f3f6f7 !important;
}
